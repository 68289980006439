import React, { useEffect } from "react";

import "./App.css";
import Home from "./components/Home/Home";

function App() {
	useEffect(() => {
		const sections = document.querySelectorAll(".section");
		sections.forEach((section) => {
			if (!section.classList.contains("active")) {
				section.classList.add("hide");
			}
		});

		window.addEventListener("load", () => {
			document.querySelector(".preloader").classList.add("fade-out");
			setTimeout(() => {
				document.querySelector(".preloader").style.display = "none";
			}, 600);
		});
	}, []);

	return (
		<div className="app">
			<div className="preloader">
				<div className="box">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			<Home />
		</div>
	);
}

export default App;
