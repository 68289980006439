import React, { useEffect } from "react";
import "./Home.css";
import Nav from "../Nav/Nav";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Portfolio from "../Portfolio/Portfolio";

function Home() {
	useEffect(() => {
		const styleSwitcherToggler = document.querySelector(
			".style-switcher-toggler"
		);
		styleSwitcherToggler.addEventListener("click", () => {
			document.querySelector(".style-switcher").classList.toggle("open");
		});

		// hide style-switcher on screen scroll
		window.addEventListener("scroll", () => {
			if (
				document.querySelector(".style-switcher").classList.contains("open")
			) {
				document.querySelector(".style-switcher").classList.remove("open");
			}
		});

		// theme light and dark mode link
		const dayNight = document.querySelector(".day-night");

		dayNight.addEventListener("click", () => {
			document.body.classList.toggle("dark");
			if (document.body.classList.contains("dark")) {
				localStorage.setItem("theme", "dark");
			} else {
				localStorage.setItem("theme", "light");
			}
			updateIcon();
		});

		function themeMode() {
			if (localStorage.getItem("theme") !== null) {
				if (localStorage.getItem("theme") === "light") {
					document.body.classList.remove("dark");
				} else {
					document.body.classList.add("dark");
				}
			}
			updateIcon();
		}

		themeMode();

		function updateIcon() {
			if (document.body.classList.contains("dark")) {
				dayNight.querySelector("i").classList.remove("fa-moon");
				dayNight.querySelector("i").classList.add("fa-sun");
			} else {
				dayNight.querySelector("i").classList.remove("fa-sun");
				dayNight.querySelector("i").classList.add("fa-moon");
			}
		}

		window.addEventListener("load", () => {
			if (document.body.classList.contains("dark")) {
				dayNight.querySelector("i").classList.add("fa-sun");
			} else {
				dayNight.querySelector("i").classList.add("fa-moon");
			}
		});
	}, []);

	const alternateStyles = document.querySelectorAll(".alternate-style");

	function setActiveStyle(color) {
		localStorage.setItem("color", color);
		changeColor();
	}

	function changeColor() {
		alternateStyles.forEach((style) => {
			if (localStorage.getItem("color") === style.getAttribute("title")) {
				style.removeAttribute("disabled");
			} else {
				style.setAttribute("disabled", "true");
			}
		});
	}

	if (localStorage.getItem("color") !== null) {
		changeColor();
	}

	return (
		<div>
			<Nav />
			<div className="fade-out-effect"></div>
			{/* <!-- home section start --> */}
			<section className="home-section section active" id="home">
				{/* <!-- effect wrap start --> */}
				<div className="effect-wrap">
					<div className="effect effect-1"></div>
					<div className="effect effect-2">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div className="effect effect-3"></div>
					<div className="effect effect-4"></div>
					<div className="effect effect-5">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
				{/* <!-- effect wrap end --> */}
				<div className="container">
					<div className="row full-screen align-items-center">
						<div className="home-text">
							<p>Hello</p>
							<h2>I'm Ucheya</h2>
							<h1>Frontend Developer & Creative Junkie</h1>
							<a
								href="#about"
								className="link-item btn-1 outer-shadow hover-in-shadow"
							>
								More About Me
							</a>
						</div>
						<div className="home-img">
							<div className="img-box inner-shadow">
								<img
									className="outer-shadow"
									src="profile-pic.jpg"
									alt="Meya"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<About />
			<Portfolio />
			<Contact />

			<div className="style-switcher outer-shadow">
				<div className="style-switcher-toggler s-icon outer-shadow hover-in-shadow">
					<i className="fas fa-cog fa-spin"></i>
				</div>
				<div className="day-night s-icon outer-shadow hover-in-shadow">
					<i className="fas "></i>
				</div>
				<h4>Theme Colors</h4>
				<div className="colors">
					<span
						className="color-1"
						onClick={() => setActiveStyle("color-1")}
					></span>
					<span
						className="color-2"
						onClick={() => setActiveStyle("color-2")}
					></span>
					<span
						className="color-3"
						onClick={() => setActiveStyle("color-3")}
					></span>
					<span
						className="color-4"
						onClick={() => setActiveStyle("color-4")}
					></span>
					<span
						className="color-5"
						onClick={() => setActiveStyle("color-5")}
					></span>
				</div>
			</div>
		</div>
	);
}

export default Home;
