import React, { useEffect } from "react";

import "./Portfolio.css";

function Portfolio() {
	useEffect(() => {
		const filterContainer = document.querySelector(".portfolio-filter"),
			portfolioItems = document.querySelectorAll(".portfolio-item");

		filterContainer.addEventListener("click", (event) => {
			if (
				event.target.classList.contains("filter-item") &&
				!event.target.classList.contains("active")
			) {
				// deactivate existing filter items
				filterContainer
					.querySelector(".active")
					.classList.remove("outer-shadow", "active");
				// active new filter items
				event.target.classList.add("active", "outer-shadow");
				const target = event.target.getAttribute("data-target");
				portfolioItems.forEach((item) => {
					if (
						target === item.getAttribute("data-category") ||
						target === "all"
					) {
						item.classList.remove("hide");
						item.classList.add("show");
					} else {
						item.classList.remove("show");
						item.classList.add("hide");
					}
				});
			}
		});
	}, []);

	return (
		<section className="portfolio-section section" id="portfolio">
			<div className="container">
				<div className="row">
					<div className="section-title">
						<h2 data-heading="portfolio">Latest Works</h2>
					</div>
				</div>
				{/* Portfolio filter start */}
				<div className="row">
					<div className="portfolio-filter">
						<span className="filter-item outer-shadow active" data-target="all">
							all
						</span>
						<span className="filter-item" data-target="react-applications">
					    React
						</span>
						<span className="filter-item" data-target="css">
							HTML/CSS
						</span>
						<span className="filter-item" data-target="threejs">
					    Three.js
						</span>
						<span className="filter-item" data-target="vue">
		          Vue
						</span>
					</div>
				</div>
				{/* Portfolio filter end */}
				{/* Portfolio items start */}
				<div className="row portfolio-items">
					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://airbnb-clone-97020.web.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="airbnb.png" alt="Airbnb Clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Airbnb Clone</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://codepen.io/merdoth/full/VwzVxeG" target="_blank">
								<div className="portfolio-item-img">
									<img src="cube.png" alt="Cube" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Bouncing Ball</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://clone-99d48.web.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="amazon.png" alt="Amazon Clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Amazon Clone</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://codepen.io/merdoth/full/VGWzee" target="_blank">
								<div className="portfolio-item-img">
									<img src="coffee.png" alt="coffee" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Coffee Machine</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://covid-19-tracker-6f46b.web.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="covid.png" alt="Covid Tracker" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Covid Tracker</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://merdoth.github.io/Crystal-loader/" target="_blank">
								<div className="portfolio-item-img">
									<img src="crystal.png" alt="crystal" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Crystal Towers</p>
						</div>
					</div>
					{/* Portfolio items end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://disney-wish.web.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="disney.png" alt="Wish Disney" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Wish Disney</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://codepen.io/merdoth/full/vzdNLm" target="_blank">
								<div className="portfolio-item-img">
									<img src="danfo.png" alt="Danfo" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Naija Danfo</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://mer-github-battles.netlify.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="github-battle.png" alt="Github Battles" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Github Battles</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://merdoth.github.io/mountain-heist/" targe="_blank">
								<div className="portfolio-item-img">
									<img src="heist.png" alt="Heist" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Mountain Heist</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://clone-848ba.web.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="google.png" alt="Google Clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Wish Google</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://merdoth.github.io/Koala/" target="_blank">
								<div className="portfolio-item-img">
									<img src="koala.png" alt="Koala" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Grey Koala</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://merdoth.github.io/Nintendo-Pad/" target="_blank">
								<div className="portfolio-item-img">
									<img src="nintendo.png" alt="Nintendo" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Nintendo Pad</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://hulu-clone-delta-coral.vercel.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="hulu.png" alt="Hulu Clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Wish Hulu</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://merdoth.github.io/Sketch-Logo/" target="_blank">
								<div className="portfolio-item-img">
									<img src="sketch.png" alt="Sketch" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Sketch Logo</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://medium-clone-6b17e.web.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="medium.png" alt="Medium Clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Wish Medium</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://codepen.io/merdoth/full/BaRXdVV" target="_blank">
								<div className="portfolio-item-img">
									<img src="css-battle.png" alt="My CSS Battle Solutions" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">CSS Battle</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://morerecipes-merdoth.herokuapp.com/" target="_blank">
								<div className="portfolio-item-img">
									<img src="morecipes.png" alt="Morecipes" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Morecipes</p>
						</div>
					</div>
					{/* Portfolio item end */}

						{/* Portfolio item start */}
						<div className="portfolio-item" data-category="threejs">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://spacebliss.netlify.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="bliss.png" alt="space bliss" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Space Bliss</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="react-applications">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://netflix-clone-fcf95.web.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="netflix.png" alt="Netflix Clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Wish Netflix</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="threejs">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://feelthespheree.netlify.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="sphere.png" alt="feel the sphere" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Feel</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://codepen.io/merdoth/full/WNePwmR" target="_blank">
								<div className="portfolio-item-img">
									<img src="tryout.png" alt="Tryout" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Tryout Logo</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="vue">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://mer-spotify-ui.netlify.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="spotify-clone.png" alt="Spotify Clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Wish Spotify</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="css">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://codepen.io/merdoth/full/aeawmx" target="_blank">
								<div className="portfolio-item-img">
									<img src="vue.png" alt="Vue Logo" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Vue Logo</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="vue">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://dry-dawn-38234.herokuapp.com/" target="_blank">
								<div className="portfolio-item-img">
									<img src="zoom.png" alt="zoom clone" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Wish Zoom</p>
						</div>
					</div>
					{/* Portfolio item end */}

					{/* Portfolio item start */}
					<div className="portfolio-item" data-category="threejs">
						<div className="portfolio-item-inner outer-shadow">
							<a href="https://earthspacee.netlify.app/" target="_blank">
								<div className="portfolio-item-img">
									<img src="earth.png" alt="earth space" />
									<span className="view-project">View Project</span>
								</div>
							</a>
							<p className="portfolio-item-title">Earth Space</p>
						</div>
					</div>
					{/* Portfolio item end */}
				</div>
			</div>
		</section>
	);
}

export default Portfolio;
