import React from "react";
import "./Experience.css";

function Experience() {
	return (
		<div>
			{/* <!-- experience start --> */}
			<div className="row">
				<div className="experience tab-content">
					<div className="row">
						<div className="timeline">
							<div className="row">
								{/* <!-- timeline item start --> */}
								<div className="timeline-item">
									<div className="timeline-item-inner outer-shadow">
										<i className="fas fa-briefcase icon"></i>
										<span>Aug, 2020 - Present</span>
										<h3>full stack developer</h3>
										<h4>Freelancer, Lagos</h4>
										<p>
										Took a break from commercial working to work on my self development,
										learn new technologies and upgrade my technology library but while I
										was at it I took on some contract freelancer work to help me practise some
										of the new knowledge I’m acquiring.
										</p>
									</div>
								</div>
								{/* <!-- timeline item end --> */}
								{/* <!-- timeline item start --> */}
								<div className="timeline-item">
									<div className="timeline-item-inner outer-shadow">
										<i className="fas fa-briefcase icon"></i>
										<span>Aug, 2019 - Aug, 2020</span>
										<h3>Frontend Developer</h3>
										<h4>Yellowcard Financials, Remote</h4>
										<p>
										Worked on the frontend application projects, application animations,
										breaking down of UI/UX designs. I also worked as a customer support dev.

										</p>
									</div>
								</div>
								{/* <!-- timeline item end --> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- experience end --> */}
			{/* <!-- education start --> */}
			<div className="row">
				<div className="education tab-content">
					<div className="row">
						<div className="timeline">
							<div className="row">
								{/* <!-- timeline item start --> */}
								<div className="timeline-item">
									<div className="timeline-item-inner outer-shadow">
										<i className="fas fa-graduation-cap icon"></i>
										<span>Feb, 2021 - Jul, 2021</span>
										<h3>CODECADEMY, FullStack Development</h3>
										<p>
											Took a 6months refresher course to help enhance my technical abilities.
										</p>
									</div>
								</div>
								{/* <!-- timeline item end --> */}

								{/* <!-- timeline item start --> */}
								<div className="timeline-item">
									<div className="timeline-item-inner outer-shadow">
										<i className="fas fa-graduation-cap icon"></i>
										<span>Oct, 2015 - Aug, 2017</span>
										<h3>AE-FUNAI, Computer Engineering</h3>
										<p>
											Studied Computer Engineering for 2years before 
											moving on to the next phase on my career path.
										</p>
									</div>
								</div>
								{/* <!-- timeline item end --> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- education end --> */}
		</div>
	);
}

export default Experience;
