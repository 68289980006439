import React, { useEffect } from "react";
import "./About.css";
import Skills from "../AboutContent/Skills/Skills";
import Experience from "../AboutContent/Experience/Experience";

function About() {
	useEffect(() => {
		const aboutSection = document.querySelector(".about-section"),
			tabsContainer = document.querySelector(".about-tabs");

		tabsContainer.addEventListener("click", (e) => {
			if (
				e.target.classList.contains("tab-item") &&
				!e.target.classList.contains("active")
			) {
				const t = e.target.getAttribute("data-target");
				// deactivate existing active 'tab-item'
				tabsContainer
					.querySelector(".active")
					.classList.remove("outer-shadow", "active");
				// activate new 'tab-item'
				e.target.classList.add("active", "outer-shadow");
				// deactivate existing active 'tab-content'
				aboutSection
					.querySelector(".tab-content.active")
					.classList.remove("active");
				// activate new 'tab-content'
				aboutSection.querySelector(t).classList.add("active");
			}
		});
	}, []);

	return (
		<section className="about-section section" id="about">
			<div className="container">
				<div className="row">
					<div className="section-title">
						<h2 data-heading="main-info">About me</h2>
					</div>
				</div>

				<div className="row">
					<div className="about-img">
						<div className="img-box inner-shadow">
							<img className="outer-shadow" src="profile-pic.jpg" alt="Meya" />
						</div>
						{/* <!-- social links start --> */}
						<div className="social-links">
							<a href="https://github.com/Merdoth" className="outer-shadow hover-in-shadow">
								<i className="fa fa-code"></i>
							</a>
							<a href="https://twitter.com/themereya" className="outer-shadow hover-in-shadow">
								<i className="fab fa-twitter"></i>
							</a>
							<a href="https://instagram.com/themereya" className="outer-shadow hover-in-shadow">
								<i className="fab fa-instagram"></i>
							</a>
							<a href="https://www.linkedin.com/in/chimereucheya-okereke-31081a134" className="outer-shadow hover-in-shadow">
								<i className="fab fa-linkedin-in"></i>
							</a>
						</div>
						{/* <!-- social links end --> */}
					</div>
					<div className="about-info">
						<p>
							<span>
								Hi! My name is Chimereucheya. I am a Frontend Developer,
							</span>
							who enjoys bringing to life amazing designs and user inclusive
							interfaces. Apart from that I'm also a creative junkie who spends 
							her time when not coding creating beautiful pieces through photography,
							painting/drawing, modelling, abstract writing, fashion designing. 
							I have a good sense of humour and enjoys a good ol' banter and never 
							to communicate or over communicate when neccessary. I'm a big advocate
							for mental health awareness, diversity and inclusion in work spaces.
						</p>
						<p>
							I also love learning about different cultures and languages, sports, 
							science, food, exploring new places and GOD.
						</p>
						<a href="NewCV.pdf" download="cv" className="btn-1 outer-shadow hover-in-shadow">
							Download CV
						</a>
						<a href="#contact" className="link-item btn-1 outer-shadow hover-in-shadow">
							Hire Me
						</a>
					</div>
				</div>
				{/* <!-- about tabs start --> */}
				<div className="row">
					<div className="about-tabs">
						<span
							className="tab-item outer-shadow active"
							data-target=".skills"
						>
							skills
						</span>
						<span className="tab-item" data-target=".experience">
							experiences
						</span>
						<span className="tab-item" data-target=".education">
							education
						</span>
					</div>
				</div>
				{/* <!-- about tabs end --> */}
				{/* <!-- skills start --> */}
				<Skills />
				{/* <!-- skills end --> */}
				{/* <!-- experience start --> */}
				<Experience />
				{/* <!-- experience end --> */}
			</div>
		</section>
	);
}

export default About;
