import React from 'react';
import "./Contact.css";

function Contact() {
  return (
    <section className="contact-section section" id="contact">
      <div className="container">
        <div className="row">
          <div className="section-title">
            <h2 data-heading="contact">Get In Touch</h2>
          </div>
        </div>

        <div className="row">
          <div className="contact-item">
            <div className="contact-item-inner outer-shadow">
              <i className="fas fa-envelope"></i>
              <span>Email</span>
              <p>Gladys.amarachig@gmail.com</p>
            </div>
          </div>

          <div className="contact-item">
            <div className="contact-item-inner outer-shadow">
              <i className="fas fa-envelope"></i>
              <span>Email</span>
              <p>merdoth1@gmail.com</p>
            </div>
          </div>

          <div className="contact-item">
            <div className="contact-item-inner outer-shadow">
              <i className="fas fa-map-marker"></i>
              <span>Location</span>
              <p>Lagos, Nigeria</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="contact-form">
            <form method="post" action="mailto:merdoth1@gmail.com" name="MerdothEmailForm">
              <div className="row">
                <div className="w-50">
                  <div className="input-group outer-shadow hover-in-shadow">
                    <input className="input-control" type="text" name="name" placeholder="Name"/>
                  </div>

                  <div className="input-group outer-shadow hover-in-shadow">
                    <input className="input-control" type="text" name="email" placeholder="Email"/>
                  </div>

                  <div className="input-group outer-shadow hover-in-shadow">
                    <input className="input-control" type="text" name="subject" placeholder="Subject"/>
                  </div>
                </div>

                <div className="w-50">
                <div className="input-group outer-shadow hover-in-shadow">
                    <textarea className="input-control" type="text" name="message" placeholder="Message"/>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="submit-btn">
                  <button type="submit" className="btn-1 outer-shadow hover-in-shadow">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Contact;
