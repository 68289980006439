import React from "react";
import "./Skills.css";

function Skills() {
	return (
		<div className="row">
			<div className="skills tab-content active">
				<div className="row">
					{/* <!-- skill item start --> */}
					<div className="skill-item">
						<p>Html</p>
						<div className="progress inner-shadow">
							<div
								className="progress-bar"
								style={{ width: "calc(70% - 14px)" }}
							>
								<span>70%</span>
							</div>
						</div>
					</div>
					{/* <!-- skill item end --> */}
					{/* <!-- skill item start --> */}
					<div className="skill-item">
						<p>Javascript</p>
						<div className="progress inner-shadow">
							<div
								className="progress-bar"
								style={{ width: "calc(60% - 14px)" }}
							>
								<span>60%</span>
							</div>
						</div>
					</div>
					{/* <!-- skill item end --> */}
					{/* <!-- skill item start --> */}
					<div className="skill-item">
						<p>CSS</p>
						<div className="progress inner-shadow">
							<div
								className="progress-bar"
								style={{ width: "calc(80% - 14px)" }}
							>
								<span>80%</span>
							</div>
						</div>
					</div>
					{/* <!-- skill item end --> */}
					{/* <!-- skill item start --> */}
					<div className="skill-item">
						<p>Vue</p>
						<div className="progress inner-shadow">
							<div
								className="progress-bar"
								style={{ width: "calc(50% - 14px)" }}
							>
								<span>50%</span>
							</div>
						</div>
					</div>
					{/* <!-- skill item end --> */}
					{/* <!-- skill item start --> */}
					<div className="skill-item">
						<p>Tailwind/SCSS</p>
						<div className="progress inner-shadow">
							<div
								className="progress-bar"
								style={{ width: "calc(60% - 14px)" }}
							>
								<span>60%</span>
							</div>
						</div>
					</div>
					{/* <!-- skill item end --> */}
					{/* <!-- skill item start --> */}
					<div className="skill-item">
						<p>React</p>
						<div className="progress inner-shadow">
							<div
								className="progress-bar"
								style={{ width: "calc(70% - 14px)" }}
							>
								<span>70%</span>
							</div>
						</div>
					</div>
					{/* <!-- skill item end --> */}
				</div>
			</div>
		</div>
	);
}

export default Skills;
