import React, { useEffect } from "react";
import "./Nav.css";

function Nav() {
	useEffect(() => {
		const hamburgerBtn = document.querySelector(".hamburger-btn"),
			navMenu = document.querySelector(".nav-menu"),
			closeNavBtn = navMenu.querySelector(".close-nav-menu");

		hamburgerBtn.addEventListener("click", showNavMenu);
		closeNavBtn.addEventListener("click", hideNavMenu);

		function showNavMenu() {
			navMenu.classList.add("open");
			// bodyScrollingToggle();
		}

		function hideNavMenu() {
			navMenu.classList.remove("open");
			fadeOutEffect();
			// bodyScrollingToggle();
		}

		function fadeOutEffect() {
			document.querySelector(".fade-out-effect").classList.add("active");
			setTimeout(() => {
				document.querySelector(".fade-out-effect").classList.remove("active");
			}, 300);
		}

		document.addEventListener("click", (event) => {
			if (event.target.classList.contains("link-item")) {
				// make sure event.target.hash has a value before overridding default behaviour
				const hash = event.target.hash;
				if (hash !== "") {
					// prevent default anchor click behavior
					event.preventDefault();
					// deactivate existing active section
					document.querySelector(".section.active").classList.add("hide");
					document.querySelector(".section.active").classList.remove("active");
					// activate new section
					document.querySelector(hash).classList.add("active");
					document.querySelector(hash).classList.remove("hide");
					// deactivate existing active navigation menu link item
					navMenu
						.querySelector(".active")
						.classList.add("outer-shadow", "hover-in-shadow");
					navMenu
						.querySelector(".active")
						.classList.remove("active", "inner-shadow");
					if (navMenu.classList.contains("open")) {
						// activate new navigation menu link item
						event.target.classList.add("active", "inner-shadow");
						event.target.classList.remove("outer-shadow", "hover-in-shadow");
						// hide navigation menu
						hideNavMenu();
					} else {
						let navItems = navMenu.querySelectorAll(".link-item");
						navItems.forEach((item) => {
							if (hash === item.hash) {
								// activate new navigation menu link item
								item.classList.add("active", "inner-shadow");
								item.classList.remove("outer-shadow", "hover-in-shadow");
							}
						});
						fadeOutEffect();
					}
				}
			}
		});
	}, []);

	return (
		<div>
			{/* eslint-disable */}
			{/* <!-- header --> */}
			<div className="header">
				<div className="container">
					<div className="row justify-content-between">
						<div className="logo">
						{/* eslint-disable-next-line */}
							<a>M</a>
						</div>
						<div className="hamburger-btn outer-shadow hover-in-shadow">
							<span></span>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- header-end --> */}
			{/* <!-- navigation menu start --> */}
			<nav className="nav-menu">
				<div className="close-nav-menu outer-shadow hover-in-shadow">
					&times;
				</div>
				<div className="nav-menu-inner">
					<ul>
						<li>
							<a href="#home" className="link-item inner-shadow active">
								Home
							</a>
						</li>
						<li>
							<a
								href="#about"
								className="link-item outer-shadow hover-in-shadow"
							>
								About
							</a>
						</li>
						<li>
							<a
								href="#portfolio"
								className="link-item outer-shadow hover-in-shadow"
							>
								Portfolio
							</a>
						</li>
						<li>
							<a
								href="#contact"
								className="link-item outer-shadow hover-in-shadow"
							>
								Contact
							</a>
						</li>
					</ul>
				</div>
				{/* <!-- copyright note --> */}
				<p className="copyright-text">&copy; The Merdoth</p>
			</nav>
			{/* <!-- navigation menu end --> */}
		</div>
	);
}

export default Nav;
